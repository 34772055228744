/** @define allproducts;  weak */
.allproducts {
  height: 370px;
}
@media only screen and (max-width: 399px) {
  .allproducts {
    background: url("/images/content/hpallproducts_400.webp") no-repeat top center;
  }
}
@media only screen and (min-width: 400px) and (max-width: 699px) {
  .allproducts {
    background: url("/images/content/hpallproducts_700.webp") no-repeat top center;
  }
}
@media only screen and (min-width: 700px) and (max-width: 1199px) {
  .allproducts {
    background: url("/images/content/hpallproducts_1200.webp") no-repeat top center;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .allproducts {
    background: url("/images/content/hpallproducts_1600.webp") no-repeat top center;
  }
}
@media only screen and (min-width: 1600px) {
  .allproducts {
    background: url("/images/content/hpallproducts_2560.webp") no-repeat top center;
  }
}
.allproducts__text {
  height: 370px;
  text-align: center;
}
.allproducts__text .subtitle {
  margin-bottom: 15px;
  color: #f7a810;
  font-size: 18px;
  line-height: 1.2;
  font-family: montserratsemibold, arial, Helvetica, sans-serif;
}
@media only screen and (min-width: 500px) {
  .allproducts__text .subtitle {
    font-size: 24px;
  }
}
@media only screen and (min-width: 1000px) {
  .allproducts__text .subtitle {
    font-size: 28px;
  }
}
.allproducts__text .title {
  margin-bottom: 20px;
  color: #ffffff;
  font-size: 26px;
  line-height: 1.2;
  font-family: montserratsemibold, arial, Helvetica, sans-serif;
}
@media only screen and (min-width: 500px) {
  .allproducts__text .title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 1000px) {
  .allproducts__text .title {
    font-size: 50px;
  }
}
.no-webp {
  /* postcss-bem-linter: ignore */
}
@media only screen and (max-width: 399px) {
  .no-webp .allproducts {
    background: url("/images/content/hpallproducts_400.jpg") no-repeat top center;
  }
}
@media only screen and (min-width: 400px) and (max-width: 699px) {
  .no-webp .allproducts {
    background: url("/images/content/hpallproducts_700.jpg") no-repeat top center;
  }
}
@media only screen and (min-width: 700px) and (max-width: 1199px) {
  .no-webp .allproducts {
    background: url("/images/content/hpallproducts_1200.jpg") no-repeat top center;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .no-webp .allproducts {
    background: url("/images/content/hpallproducts_1600.jpg") no-repeat top center;
  }
}
@media only screen and (min-width: 1600px) {
  .no-webp .allproducts {
    background: url("/images/content/hpallproducts_2560.jpg") no-repeat top center;
  }
}
/*# sourceMappingURL=css/allproductshp.css.map */